import { isPast } from 'date-fns';
import { QuoteJourneyFormData } from '../../../../helpers/forms/quote';

export const isStartDateInPast = (values: QuoteJourneyFormData): boolean => {
  if (
    values.coverStartQuestion?.id === 'ASAP' &&
    isPast(new Date(values.asapStartDate || ''))
  ) {
    return true;
  }

  if (
    values.coverStartQuestion?.id === 'EXACT' &&
    values.exactStartDate &&
    values.exactStartHour?.id &&
    values.exactStartMinute?.id
  ) {
    const exactStartDate = new Date(values.exactStartDate.parsedDate || '');
    exactStartDate.setHours(parseInt(values.exactStartHour.id || '', 10));
    exactStartDate.setMinutes(parseInt(values.exactStartMinute.id || '', 10));

    return isPast(exactStartDate);
  }

  return false;
};
